.Container-Visualizar-Contagem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.Dados-Contagem-Usuario {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0px 0px 0px;
}

.Data-Contagem-Encerrada.MuiTypography-body1 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #697788;
}
.Nome-Usuario-Contagem-Encerrada.MuiTypography-body1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #02226A;
}
.Loja-Contagem-Encerrada.MuiTypography-body1 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #91B7F0;
}

.Campo-Busca-Equip-Encerrada {
  width: 100%;
  height: 34px;
  margin: 20px 0px 0px 0px;
}
.Campo-Busca-Encerrada .MuiInputBase-input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #001147;
  margin-left: 24px;
}
.Campo-Busca-Encerrada .MuiInputAdornment-root {
  color: #043D94;
  margin-right: 24px;
}
.Campo-Busca-Encerrada .MuiInputBase-root::before {
  content: none;
}
.Campo-Busca-Encerrada .MuiInputBase-root::after {
  content: none;
}

.Ordenacao-Encerradas {
  width: 100%;
  height: 30px;
  border-top: 1px solid #AEC3D2;
  border-bottom: 1px solid #AEC3D2;
}
.Contagem-Encerrada-Filtro-Ordenacao.MuiInputBase-root {
  margin-left: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #02226A;
}
.Contagem-Encerrada-Filtro-Ordenacao.MuiInput-underline:hover {
  border: none;
}
.Contagem-Encerrada-Filtro-Ordenacao .MuiSvgIcon-root {
  color: #02226A;
}
.Contagem-Encerrada-Filtro-Ordenacao.MuiInput-underline::before {
  left: none;
  right: none;
  bottom: none;
  border: none;
  transition: none;
  content: none;
}
.Contagem-Encerrada-Filtro-Ordenacao.MuiInput-underline::after {
  content: none;
}

.List-Root-Encerradas {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-bottom: 1px solid #AEC3D2;
}
.List-Root-Encerradas::-webkit-scrollbar {
  display: none;
}

.Card-Contagem-Encerrada.MuiPaper-root {
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}

.Card-Content-Encerradas.MuiCardContent-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.Card-Content-Encerradas.MuiCardContent-root:last-child {
  padding-bottom: 15px;
}

.Area-Informativa-Encerrada {
  display: flex;
  flex-direction: column;
}

.Area-Cod-Grupo-Encerrada {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.Area-Botoes-Encerrada {
  display: flex;
  justify-content: flex-end;
}

.Checked-Disabled.MuiCheckbox-colorPrimary.Mui-disabled {
  color: #AEC3D2;
}
.Checked-Disabled .MuiSvgIcon-root {
  font-size: 30px;
}
.Checked-Disabled.MuiButtonBase-root {
  padding: 0px;
}

.Button-Disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  border: 1px solid #AEC3D2;
  color: #AEC3D2;
  border-radius: 4px;
  width: 26px;
  height: 26px;
}

.Descricao-Equip-Divergente.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  display: flex;
  align-items: center;
  color: #860539;
}
.Cod-Equip-Divergente.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  display: flex;
  align-items: center;
  color: #4D5C6C;
}
.Grupo-Equip-Divergente.MuiTypography-body1 {
  margin-left: 8px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #4D5C6C;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
}

.Progresso-Circular-Encerrada.MuiCircularProgress-root {
  color: #95C6F4;
}

.Botoes-Input-Encerradas.MuiIconButton-root {
  padding: 0px;
  margin-right: 20px;
  color: #02226A;
}

.Botoes-Input-Encerradas.MuiIconButton-root.Mui-disabled {
  color: #02226A;
}

.Box-botao-enviar-email.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 93px;
  margin: auto 0px 0px 0px;
}

// @media (max-height: 568px) {
//   .List-Root-Encerradas {
//     height: 69%;
//   }
// }
