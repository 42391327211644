@import "../../bootstrap/Tema/variables";

.box-container-login.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.Box-Content-Login.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  height: 100%;
}

.Titulo-Login.MuiTypography-body1{
  font-size: 24px;
  font-weight: 500;
  color: $brandcolor-primary-darkest;
  text-align: center;
  line-height: 120%;
  margin: 40px 0px 60px 0px;
  font-style: normal;
}

.Box-Content-Login .Inputs {
  margin-bottom: 40px;
}

.Box-Content-Login .Button {
  margin-top: 75px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
}

.Button.MuiButton-root {
  padding: 16px 24px;
  height: 50px;
}

.Button.MuiButton-contained.Mui-disabled {
  color: #697991;
  background-color: #183868;
}
.Button.MuiButton-root:hover.Mui-disabled {
  color: #697991;
  background-color: #183868;
}

.Form-Login {
  width: 100%;
}

.Progresso-Circular-Login.MuiCircularProgress-root {
  color: #95C6F4;
}

.Progresso-Circular-Login {
  margin-top: 210px;
}
