@import "../../../../bootstrap/Tema/variables";

.Scroll-Infinita::-webkit-scrollbar {
  display: none;
}

.Scroll-Infinita {
  overflow: auto;
  height: 325px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.List-Cards-Encerradas .MuiPaper-root {
  background-color: transparent;
}
.List-Cards-Encerradas .MuiPaper-elevation1 {
  box-shadow: none;
}

.Primeira-Linha-Encerradas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Primeira-Linha-Encerradas div{
  display: flex;
  align-items: center;
}

.linha-funcionario-seta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Nome-Codigo-Loja-Encerradas.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #001147;

}

.icone-seta-card.MuiSvgIcon-root{
  color: #001147;
  height: 22px;
  width: 22px;
}

.linha-status-contagens-encerradas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Com-Divergencia.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color:  #BB0A30;
  background-color: #FFE7E2;
  border-radius: 16px;
  padding: 1px 5px;
}

.Sem-Divergencia.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #218132;
  background-color: #E1FFE1;
  border-radius: 16px;
  padding: 1px 5px;
}

.Data-Contagem-Encerradas.MuiTypography-body1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #697788;
  text-align: right;
}

.Nome-Usuario-Encerradas.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  color: #043D94;
}

.Tipo-Contagem-Encerradas.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: right;
  color: #001147;
}

@media (max-height: 700px) {
  .Nome-Usuario.MuiTypography-root {
    font-size: 12px;
  }

  .Tipo-Contagem.MuiTypography-root {
    font-size: 12px;
  }
}

@media (min-height: 480px) {
  .Scroll-Infinita {
    height: 75px;
  }
}

@media (min-height: 568px) {
  .Scroll-Infinita {
    height: 170px;
  }
}

@media (min-height: 640px) {
  .Scroll-Infinita {
    height: 235px;
  }
}

@media (min-height: 720px) {
  .Scroll-Infinita {
    height: 300px;
  }
}

// @media (min-height: 736px) {
//   .Scroll-Infinita {
//     height: 470px;
//   }
// }

@media (min-height: 812px) {
  .Scroll-Infinita {
    height: 410px;
  }
}

@media (min-height: 947px) {
  .Scroll-Infinita {
    height: 500px;
  }
}

@media (min-height: 979px) {
  .Scroll-Infinita {
    height: 530px;
  }
}

@media (min-height: 1024px) {
  .Scroll-Infinita {
    height: 575px;
  }
}
