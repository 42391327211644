html, body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: #F5F5F5;
}

#root {
  width: 100vw;
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}
