@import "../../../bootstrap/Tema/variables";

.Input-Select-Container {
  display: flex;
  flex-direction: column;
}

.Input-Select {
  margin-top: 20px;
}

.Input-Select .react-select__single-value {
  color:  #043D94;
  font-size: 14px;
}

.Input-Select .css-yk16xz-control {
  background-color: #EEEEEE;
  border: none;
}

.Input-Select .css-1pahdxg-control:hover {
  background-color: #EEEEEE;
}
.Input-Select .css-1pahdxg-control {
  background-color: #EEEEEE;
  border: none;
  box-shadow: 0 0 0 1px #02226A;
}

.Input-Select .css-1rhbuit-multiValue {
  display: flex;
  align-items: center;
  background-color: #043D94;
  color: #fff;
  border-radius: 20px;
  padding: 1px;
}
.Input-Select .css-xb97g8:hover {
  background-color: #043D94;
  border-radius: 30px;
  color: #fff;
}

.Input-Select .css-12jo7m5 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;
}

.Icone-Busca-Select.MuiSvgIcon-root {
  color: #043D94
}
.Input-Select .react-select__placeholder {
  font-size: 14px;
  color: #8794A5;
  letter-spacing: 0.4px;
}

.Input-Select-Divisao-Erros {
  display: flex;
  align-items: center;
}

.Input-Select-Error {
  display: flex;
  justify-content: flex-start;
  color: $dangercolor-medium;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  margin-top: 3px;
}

.Input-Select .react-select__menu {
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px #B4B4B4;
  border: 1px solid #FFFFFF;
}

.Input-Select .react-select__menu-list::-webkit-scrollbar {
  display: none;
}

.Input-Select .react-select__option {
  border-bottom: 1px solid #AEC3D2;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #043D94;
}

@media (max-height: 640px) {
  .Menu-Search .react-select__menu-list {
    height: 163px;
  }
}

@media (max-height: 568px) {
  .Menu-Search .react-select__menu-list {
    height: 125px;
  }
}
