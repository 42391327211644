.Visualizacao-Contagem.MuiGrid-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.Visualizacao-Contagem-Grid-Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}
