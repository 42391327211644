@import "../../../bootstrap/Tema/variables";

.Contagem-Andamento-Container-Lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Busca-Equipamento-Andamento {
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid #AEC3D2;
}

.Campo-Filtro-Equip-Nome .MuiInputBase-input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #001147;
  margin-left: 24px;
}
.Campo-Filtro-Equip-Nome .MuiInputBase-root::before {
  left: none;
  right: none;
  bottom: none;
  border: none;
  transition: none;
  content: none;
}
.Campo-Filtro-Equip-Nome .MuiInputBase-root::after {
  left: none;
  right: none;
  bottom: none;
  border: none;
  transition: none;
  transform: none;
  content: none;
}
.Campo-Filtro-Equip-Nome .MuiButtonBase-root{
  padding: 2px;
  color: #043D94;
  margin-right: 20px;
}
.Campo-Filtro-Equip-Nome .MuiButtonBase-root.Mui-disabled {
  color: #043D94;
}

.Contagem-Andamento-Filtro {
  display: flex;
  align-items: center;
  height: 40px;
}
.Contagem-Andamento-Filtro-Ordenacao.MuiInputBase-root {
  margin-left: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #02226A;
}
.Contagem-Andamento-Filtro-Ordenacao.MuiInput-underline:hover {
  border: none;
}
.Contagem-Andamento-Filtro-Ordenacao .MuiSvgIcon-root {
  color: #02226A;
}
.Contagem-Andamento-Filtro-Ordenacao.MuiInput-underline::before {
  left: none;
  right: none;
  bottom: none;
  border: none;
  transition: none;
  content: none;
}
.Contagem-Andamento-Filtro-Ordenacao.MuiInput-underline::after {
  left: none;
  right: none;
  bottom: none;
  border: none;
  transition: none;
  transform: none;
  content: none;
}

.Contagem-Andamento-Adicionar {
  border-top: 1px solid #AEC3D2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.Contagem-Andamento-Lista {
  border-top: 1px solid $neutralcolor-dark-100;
  border-bottom: 1px solid $neutralcolor-dark-100;
  display: flex;
  flex-direction: column;
  height: 490px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Contagem-Andamento-Lista::-webkit-scrollbar {
  display: none;
}

.ListItem-Contagem-Andamento.MuiListItem-root {
  padding: 0px;
}

.Card-Contagem-Andamento.MuiCard-root {
  width: 100%;
  box-shadow: none;
  background-color: transparent;
}

.CardContent-Contagem-Andamento.MuiCardContent-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.CardContent-Contagem-Andamento.MuiCardContent-root:last-child {
  padding-bottom: 15px;
}

.Contagem-Andamento-Container-Lista .Opcoes-Descarte-Finalizar {
  margin-top: 40px;
  margin-bottom: 24px;
}

.Dados-Contagem-Andamento {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.Nome-Grupo-Codigo {
  display: flex;
  margin-top: 4px;
}

.Descricao-Equip.MuiTypography-body1 {
  color: $brandcolor-primary-light;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
}
.Codigo-Equip.MuiTypography-body1 {
  color: $neutralcolor-dark-400;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
}
.Nome-Grupo-Equip.MuiTypography-root {
  margin-left: 8px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: $neutralcolor-dark-400;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
}

.Opcoes-Descarte-Finalizar {
  display: flex;
  justify-content: center;
}

.Check-Qtd.MuiCheckbox-root {
  padding: 0px 0px 0px 4px;
  color: #043D94;
  box-shadow: none;
}

.Check-Qtd .MuiSvgIcon-root {
  font-size: 31px;
}

@media (max-height: 768px) {
  .Contagem-Andamento-Container-Lista {
    height: 90%;
  }
}

@media (max-height: 568px) {
  .Contagem-Andamento-Container-Lista {
    height: 89%;
  }
}

//Estilo do Modal
.Botao-Contar-Equipamento.MuiButton-root {
  display: flex;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #043D94;
  border: 1px solid #043D94;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: auto;
  width: 28px;
  height: 28px;
}

.Dialog-info-quantidade .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 314px;
  width: 360px;
  background: #F5F5F5;
  margin: auto 0px 0px 0px;
  border-radius: 16px 16px 0px 0px;
}

.botao-arrastar-fechar.MuiButton-root {
  background: #C6D9E3;
  width: 56px;
  height: 6px;
  padding: 0px;
  margin: 8px 0px 0px 0px;
}

.Informativo-Quantidade.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
  margin: 16px 0px 16px 0px;
}

.Alerta-Quantidade.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  color: #4D5C6C;
  margin: 0px 0px 24px 0px;
}

.Input-Quantidade-Contada.MuiFormControl-root {
  width: 212px;
  height: 54px;
}
.Input-Quantidade-Contada .MuiOutlinedInput-root {
  border-radius: 8px;
}
.Input-Quantidade-Contada .MuiOutlinedInput-input {
  padding: 0px;
}
.Input-Quantidade-Contada .MuiInputBase-input{
  height: 52px;
  text-align: center;
}
.Input-Quantidade-Contada .MuiInputBase-root {
  color: #8794A5;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
}

.Acoes-Contagem-Equipamento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto 0px 25px 0px;
  width: 100%;
  height: 108px;
  padding: 0px 24px;
}
.Confirmar-Quantidade.MuiButtonBase-root {
  background: #02226A;
  border-radius: 8px;
  text-transform: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 16px 24px;
  color: #FFFFFF;
  width: 100%;
  height: 50px;
}
.Confirmar-Quantidade.MuiButtonBase-root:hover {
  background: #042f94;
  color: #FFFFFF;
}
.Cancelar-Quantidade.MuiButtonBase-root {
  background: #FFFFFF;
  border: 1px solid #02226A;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #02226A;
  text-transform: none;
  padding: 16px 24px;
  width: 100%;
  height: 50px;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}
input[type=number] {
 -moz-appearance: textfield;
 appearance: textfield;

}

.Loader-Equipamentos {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: 1px solid #AEC3D2;
  border-bottom: 1px solid #AEC3D2;
}
.Progresso-Circular-Andamento.MuiCircularProgress-root {
  color: #95C6F4;
}

@media (min-height: 854px)  {
  .Contagem-Andamento-Lista {
    height: 530px;
  }
}

@media (min-height: 960px)  {
  .Contagem-Andamento-Lista {
    height: 650px;
  }
}

@media (min-height: 947px)  {
  .Contagem-Andamento-Lista {
    height: 630px;
  }
}

@media (min-height: 979px)  {
  .Contagem-Andamento-Lista {
    height: 650px;
  }
}

@media (min-height: 1024px) {
  .Contagem-Andamento-Lista {
    height: 700px;
  }
}

@media (min-height: 1280px) {
  .Contagem-Andamento-Lista {
    height: 950px;
  }
}

@media (min-height: 1366px) {
  .Contagem-Andamento-Lista {
    height: 1050px;
  }
}
