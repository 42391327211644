@import "../../../../bootstrap/Tema/variables";

.Sem-Contagem.MuiTypography-body1 {
  margin-top: 16px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
}

.List-Cards-Ativas {
  margin-top: -13px;
}
.List-Cards-Ativas .MuiPaper-root {
  background-color: transparent;
}
.List-Cards-Ativas .MuiPaper-elevation1 {
  box-shadow: none;
}

.Segunda-Linha-Ativas {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icone-seta-card.MuiSvgIcon-root{
  color: #001147;
  height: 22px;
  width: 22px;
}

.Nome-Codigo-Loja.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #001147;
}

.Terceira-Linha-Ativas {
  display: flex;
  justify-content: space-between;
}

.Data-Contagem-Ativas.MuiTypography-body1 {
  text-align: right;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #697788;
}

.Nome-Usuario-Ativas.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 136%;
  color: #043D94;
}

.Tipo-Contagem-Ativas.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  color: #001147;
  text-align: right;
}

@media(max-height: 640px) {
  .Tipo-Contagem-Ativas.MuiTypography-body1 {
    font-size: 12px;
  }

  .Nome-Usuario-Ativas.MuiTypography-body1 {
    font-size: 12px;
  }
}
