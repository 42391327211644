@import "../../../bootstrap/Tema/variables";

.Corpo-Container {
  padding: 0px 8px 8px 0px;
  margin-top: 8px;
  /*
    Caso o app venha ter mais opções de menu
    basta descomentar o campo abaixo para criar uma divisão entre eles
  */
  // border-bottom: 1px solid #EEEEEE;

  & li {
    height: 48px;
    padding: 15px 18px;
    border-radius: 0px 32px 32px 0px;
  };
  & li:focus, & li:hover {
    background: #EEEEEE;
    transition: all 0.5s ease;
  }
}

.Corpo-Icone {
  fill: #35424f;
}

.Corpo-Texto {
  & .MuiTypography-root {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-left: -33px;
  }
}
