.Container-Esqueleto .MuiCardContent-root {
  padding: 3px;
  width: 97%;
}
.Container-Esqueleto .MuiCardContent-root:last-child {
  padding: 2px;
}
.Container-Esqueleto .MuiPaper-root {
  background-color: transparent;
}
.Container-Esqueleto .MuiPaper-elevation1 {
  box-shadow: none;
}

.Primeira-Linha-Esqueleto {
  display: flex;
  justify-content: space-between;
}

.Status-Esqueleto div{
  width: 43px;
  height: 11px;
  border: 1px solid #AEC3D2;
  box-sizing: border-box;
}

.Data-Esqueleto div{
  width: 64px;
  height: 12px;
  border-radius: 2px;
  border: 1px solid #AEC3D2;
  box-sizing: border-box;
}

.Segunda-Linha-Esqueleto {
  margin-top: 3px;
}

.Segunda-Linha-Esqueleto div {
  width: 149px;
  height: 19px;
  border: 1px solid #AEC3D2;
  box-sizing: border-box;
}

.Terceira-Linha-Esqueleto {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.Terceira-Linha-Esqueleto div:first-child {
  width: 150px;
  height: 12px;
}

.Terceira-Linha-Esqueleto div:last-child {
  width: 106px;
  height: 12px;
}

.List-Cards-Esqueleto .Card-Esqueleto{
  width: 100%;
  margin-bottom: 10px;
}
