.header-contagens-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 19px 24px;
}
.user {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #FFFFFF;
}

.header-contagens-container .MuiIconButton-colorPrimary {
  color: #FFFFFF;
}
.header-contagens-container .MuiIconButton-root {
  padding: 0px;
}
