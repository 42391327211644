.container-divergencia.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.nome-equipamento.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #001147;
  margin: 8px auto 0px 24px;
}
.codigo-equipamento.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 136%;
  color: #4D5C6C;
  margin: 8px auto 24px 24px;
}
.lista-ordenada {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.list-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px 16px 24px;
  border-bottom: 1px solid #C6D9E3;
}
.chave.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #02226A;
}
.valor-positivo.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  text-align: right;
  color: #02226A;
}
.valor-negativo {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #BB0A30;
}

li:first-child {
  border-top: 1px solid #C6D9E3;
}

.content-lista-acoes.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}
