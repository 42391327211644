@import "../../bootstrap/Tema/variables";

.Contagens-container.MuiGrid-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #041D5D;
}

.Contagens-items.MuiGrid-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}
