@import "../../../bootstrap/Tema/variables";

.Scroll-Infinita-Historico::-webkit-scrollbar {
  display: none;
}
.Scroll-Infinita-Historico {
  overflow: auto;
  height: 450px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.List-Card-Historico-contagens .MuiPaper-root {
  background-color: transparent;
}
.List-Card-Historico-contagens .MuiPaper-elevation1 {
  box-shadow: none;
}

.Cards-Historico-contagens.MuiPaper-root {
  width: 100%;
}

.Card-Content-Historico-contagens.MuiCardContent-root {
  padding: 0px;
}
.Card-Content-Historico-contagens.MuiCardContent-root:last-child {
  padding: 0px;
}

.Status-Contagem-Historico {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Data-Historico-contagens.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #697788;
  text-align: right;
}

.Com-Divergencia-Historico.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color:  #BB0A30;
  background-color: #FFE7E2;
  border-radius: 16px;
  padding: 1px 5px;
}

.Sem-Divergencia-Historico.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #218132;
  background-color: #E1FFE1;
  border-radius: 16px;
  padding: 1px 5px;
}

.linha-usuario-seta, .linha-status-historico-contagens {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Nome-Codigo-Loja-Historico.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #001147;
  text-align: left;
}

.icone-seta-card.MuiSvgIcon-root{
  color: #001147;
  height: 22px;
  width: 22px;
}
.Botao-Visualizar-Historico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Nome-Usuario-Historico.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 136%;
  color: #043D94;
}

.Tipo-Contagem-Historico.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: right;
  color: #001147;
}

.Sem-Historico-Contagens.MuiTypography-body1 {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #043D94;
}

@media (max-height: 700px) {
  .Nome-Usuario-Historico.MuiTypography-root {
    font-size: 12px;
  }

  .Tipo-Contagem-Historico.MuiTypography-root {
    font-size: 12px;
  }
}

@media (min-height: 480px) {
  .Scroll-Infinita-Historico {
    height: 240px;
  }
}

@media (min-height: 568px) {
  .Scroll-Infinita-Historico {
    height: 320px;
  }
}

@media (min-height: 640px) {
  .Scroll-Infinita-Historico {
    height: 380px;
  }
}

@media (min-height: 720px) {
  .Scroll-Infinita-Historico {
    height: 455px;
  }
}

@media (min-height: 736px) {
  .Scroll-Infinita-Historico {
    height: 470px;
  }
}

@media (min-height: 812px) {
  .Scroll-Infinita-Historico {
    height: 535px;
  }
}

@media (min-height: 947px) {
  .Scroll-Infinita-Historico {
    height: 645px;
  }
}

@media (min-height: 979px) {
  .Scroll-Infinita-Historico {
    height: 680px;
  }
}

@media (min-height: 1024px) {
  .Scroll-Infinita-Historico {
    height: 730px;
  }
}
