@import "../../../../bootstrap/Tema/variables";

.Botao-Descartar.MuiButtonBase-root {
  border: 1px solid $brandcolor-primary-medium;
  border-radius: 8px;
  padding: 16px 24px;
  width: 152px;
  color: $brandcolor-primary-medium;
}

.Botao-Descartar .MuiButton-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-transform: none;
}

.Dialog-Descarte-Contagem .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 246px;
  width: 360px;
  background: #F5F5F5;
  margin: auto 0px 0px 0px;
  border-radius: 16px 16px 0px 0px;
}

.arrastar-fechar.MuiButton-root {
  background: #C6D9E3;
  width: 56px;
  height: 6px;
  padding: 0px;
  margin: 11px 0px 16px 0px;
}

.Informativo.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
}
.Alerta.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #4D5C6C;
  margin: 16px 0px 24px 0px;
}

.div-botoes-contagem-andamento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 108px;
  padding: 0px 24px;
}

.Manter-Contagem.MuiButtonBase-root {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: #02226A;
  color: #FFFFFF;
  text-transform: none;
}
.Descarte-Contagem.MuiButtonBase-root {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  // background: #EA6568;
  color: #02226A;
  border: 1px solid #02226A;
  text-transform: none;
}
.Manter-Contagem.MuiButtonBase-root:hover {
  background-color: #02226A;
}

.Progresso-Circular-Descarte-Contagem.MuiCircularProgress-root {
  color: #95C6F4;
}
