.container {
  height: 25px;
  background-color: #AEC3D2;
  position: relative;
  border-radius: 2px;
}
.container .progress-bar{
 position: absolute;
 height: 100%;
 background-color: #a4b6c4;
 animation: progress-animation 0.9s infinite;
}
@keyframes progress-animation{
  0% { width: 0%; }
  100% { width: 100%}
}
