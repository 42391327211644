@import "../../../bootstrap/Tema/variables";

.Container-InputPassword {
  display: flex;
  flex-wrap: wrap;
}

.Container-InputPassword .Error {
  display: flex;
  justify-content: flex-start;
  color: $dangercolor-medium;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  margin: 0px 0px 0px 2px;
}

.Container-InputPassword .MuiFormControl-root {
  width: 100%;
}

.Icone-Visualizar.MuiSvgIcon-root {
  color: $dangercolor-medium;
}

.Alterar-Cor-Senha .MuiFormLabel-root {
  color: $dangercolor-medium;
}

.Alterar-Cor-Senha .MuiInputLabel-shrink {
  color: $dangercolor-medium;
}
