@import "../../../../bootstrap/Tema/variables";

.Botao-Finalizar.MuiButtonBase-root {
  background-color: $brandcolor-primary-medium;
  border: 1px solid $brandcolor-primary-medium;
  border-radius: 8px;
  padding: 16px 24px;
  color: #FFFFFF;
  width: 152px;
  margin-left: 10px;
}
.Botao-Finalizar.MuiButtonBase-root:hover {
  background-color: $brandcolor-primary-medium;
  border: 1px solid $brandcolor-primary-medium;
}

.Botao-Finalizar .MuiButton-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-transform: none;
}

.Dialog-Finalizar-Action.MuiDialogActions-root {
  justify-content: center;
  align-items: center;
}
.Dialog-Finalizar-Contagem .MuiPaper-root{
  width: 100%;
  height: 100%;
  background-color: #E5E5E5;
}
.Dialog-Finalizar-Contagem .MuiDialog-paperScrollPaper {
  max-height: none;
}
.Dialog-Finalizar-Contagem .MuiDialog-paper {
  margin: auto;
}
.Dialog-Finalizar-Contagem .MuiPaper-rounded {
  border-radius: 24px 24px 0px 0px;
}

.Dialog-Content-Finalizar-Contagem.MuiDialogContent-root:first-child {
  padding-top: 0px;
}
.Dialog-Content-Finalizar-Contagem.MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 8px 16px;
  overflow: hidden;
}

.Botao-Fechar-Finalizar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.Botao-X-Finalizar.MuiButtonBase-root {
  padding: 0px;
  color: #043D94;
}
.Botao-X-Finalizar .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.Check-Menssagem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.Icon-Check.MuiSvgIcon-root{
  color: #61C662;
  width: 120px;
  height: 120px;
}
.Mensagem-Encerrada.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #218132;
}
.Botao-Concluir-Contagem.MuiButtonBase-root {
  width: 312px;
  height: 50px;
  background: #02226A;
  border-radius: 8px;
  color: #FFFFFF;
  margin-bottom: 57px;
}
.Botao-Concluir-Contagem.MuiButtonBase-root:hover {
  color: #FFFFFF;
  background: #032f96;
}
.Encerrando-Contagem.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #02226A;
  margin-top: 60px;
}

.Circular-Progress {
  margin-top: -100px;
}
.Circular-Progress .MuiCircularProgress-svg{
  color: #a4b6c4;
}
