@import "../../bootstrap/Tema/variables";

.Modal-Descarte-Notificacao {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal-Paper-Notificacao {
  background: #F5F5F5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 265px;
  width: 312px;
  border-radius: 8px;
  padding: 20px;
}

.Botao-Voltar-Texto-Notificacao {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.Informativo-Notificacao.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #043D94;
  text-align: center;
  width: 212px;
  height: 57px;
  margin-right: 10%;
}

.Alerta-Notificacao.MuiTypography-root {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  line-height: 120%;
  color: #4D5C6C;
  margin-top: 8px;
}

.Content-Actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
