.container-btn-observacao.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #C6D9E3;
}
.lista-observacao.MuiList-root {
  width: 100%;
}
.list-item-obs.MuiButtonBase-root{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: #043D94;
  padding: 0px 24px;
}
.collapse-observacao.MuiCollapse-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 28px 24px 0px 24px;
}
.collapse-observacao .MuiCollapse-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.collapse-observacao .MuiCollapse-wrapperInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.text-field-observacao.MuiFormControl-root {
  width: 100%;
}
.text-field-observacao .MuiOutlinedInput-notchedOutline {
  border-color: #AEC3D2;
}
.contador-observacao.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 136%;
  text-align: right;
  color: #02226A;
  margin: 3px 0px 24px auto;
}
.box-botoes-obs.MuiBox-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
}
.btn-adicionar-obs.MuiButtonBase-root {
  width: 312px;
  height: 50px;
  background: #02226A;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #FFFFFF;
  text-transform: none;
  margin: auto;
}
.btn-adicionar-obs.MuiButtonBase-root:hover, .btn-editar-obs.MuiButtonBase-root:hover {
  background: #02226A;
}
.btn-editar-obs.MuiButtonBase-root {
  width: 144px;
  height: 50px;
  background: #02226A;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #FFFFFF;
  text-transform: none;
}
.btn-excluir-obs.MuiButtonBase-root {
  width: 144px;
  height: 50px;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #02226A;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #02226A;
  text-transform: none;
}
