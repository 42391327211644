.Index-Suspense {
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.Progresso-Circular {
  margin-top: 210px;
}

.Progresso-Circular.MuiCircularProgress-root {
  color: #95C6F4;
}
