.container-modal-mais-detalhe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 84px;
  border-bottom: 1px solid #AEC3D2;
}
.btn-modal-mais-detalhe.MuiButton-root {
  text-transform: none;
  padding: 16px 24px 16px 24px;
  border: 1px solid #02226A;
  width: 176px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #02226A;
}
.dialog-mais-detalhes .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 450px;
  width: 100%;
  background: #F5F5F5;
  margin: auto 0px 0px 0px;
  border-radius: 16px 16px 0px 0px;
}
.btn-arrasta-fecha-detalhes.MuiButton-root {
  background: #C6D9E3;
  width: 50px;
  height: 8px;
  padding: 0px;
  margin: 11px 0px 4px 0px;
}
.btn-arrasta-fecha-detalhes.MuiButton-root:hover {
  background: #C6D9E3;
}
.btn-icone-fechar.MuiIconButton-root {
  padding: 0px;
  margin: 0px 24px 28px auto;
}
.btn-icone-fechar .MuiSvgIcon-root {
  color: #043D94;
  min-height: 20px;
  width: 20px;
}
.nome-equip-dialog.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #043D94;
  margin: 0px auto 24px 24px;
  text-align: left;
}
.list-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.list-item-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 16px 24px;
  border-bottom: 1px solid #C6D9E3;
}
.chave-modal.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  color: #041D5D;
}
.valor-disponivel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  text-align: right;
  color: #34A13F;
}
.valor-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  text-align: right;
  color: #041D5D;
}
