@import "../../../bootstrap/Tema/variables";

.Rodape-Container {
  justify-content: center;
  align-items: center;
  padding: 16px;
  // border-top: 1px solid #EEEEEE;
  position: absolute;
  bottom: 0;

  & button {
    background: #BB0A30;
    color: #FFFFFF;
    padding: 16px 32px;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 1.25px;
  };

  & button:focus, & button:hover {
    background: #BB0A30;
    color: #FFFFFF;
  }
}

.Finalizando-Sessao.MuiCircularProgress-root {
  color: #95C6F4;
}
.Finalizando-Sessao {
  margin-bottom: 10px;
}
