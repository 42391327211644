@import "../../bootstrap/Tema/variables";

.Tab-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.Tabs-unstyled.TabsUnstyled-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  background: #F7F7F7;
}
.Tabs-list-unstyled.TabsListUnstyled-root {
  background: #FFFFFF;
  border-radius: 16px;
  margin: 30px 0px 0px 0px;
}
.Tab-unstyled.TabUnstyled-root {
  width: 100px;
  height: 45px;
  background-color: transparent;
  border: none;
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
}
.Tab-unstyled.Mui-selected {
  background-color: #D8E9F1;
  color: #043D94;
  font-weight: 500;
}
.Tab-panel-unstyled.TabPanelUnstyled-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 24px;
}

.titulo-contagens.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #001147;
  margin: 0px -47px 0px 0px;
}
.Box-Titulo-Filtro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  margin: 16px 0px 0px 0px;
  padding: 0px 24px 0px 24px;
}
.Box-Titulo-Filtro .Botao-Filtrar.MuiButtonBase-root {
  margin-top: 0px;
  margin-left: 0px;
}
.Box-Titulo-Filtro .Botao-Filtrar .MuiButton-startIcon {
  margin: auto;
}
#mui-1-P-2, #mui-1-P-1 {
  margin: 10px 0px 0px 0px;
}
