@import "../../bootstrap/Tema/variables";

.PaginaIncial-container.MuiGrid-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.PaginaInicial-item.MuiGrid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}
