
// Do not edit directly
// Generated on Wed, 21 Oct 2020 13:35:18 GMT

$brandcolor-primary-darkest: #001147;
$brandcolor-primary-dark: #041D5D;
$brandcolor-primary-medium: #02226A;
$brandcolor-primary-light: #043D94;
$brandcolor-primary-lightest: #95C6F4;
$brandcolor-secondary-darkest: #D87109;
$brandcolor-secondary-dark: #FC900D;
$brandcolor-secondary-medium: #FFA600;
$brandcolor-secondary-light: #FFCC00;
$brandcolor-secondary-lightest: #FFF099;
$complementarycolor-primary-darkest: #95C6F4;
$complementarycolor-primary-dark: #AFD9F8;
$complementarycolor-primary-medium: #C0E5FB;
$complementarycolor-primary-light: #D6F0FD;
$complementarycolor-primary-lightest: #EAF8FE;
$complementarycolor-secondary-darkest: #FFF099;
$complementarycolor-secondary-dark: #FFF4B2;
$complementarycolor-secondary-medium: #FFF7C1;
$complementarycolor-secondary-light: #FFFAD6;
$complementarycolor-secondary-lightest: #FFFDEA;
$neutralcolor-dark-100: #AEC3D2;
$neutralcolor-dark-200: #8794A5;
$neutralcolor-dark-300: #697788;
$neutralcolor-dark-400: #4D5C6C;
$neutralcolor-dark-500: #35424F;
$neutralcolor-dark-600: #1F2933;
$neutralcolor-light-100: #FFFFFF;
$neutralcolor-light-200: #F7F7F7;
$neutralcolor-light-300: #F5F5F5;
$neutralcolor-light-400: #E9F6FA;
$neutralcolor-light-500: #D8E9F1;
$neutralcolor-light-600: #C6D9E3;
$dangercolor-darkest: #6C0337;
$dangercolor-dark: #860539;
$dangercolor-medium: #BB0A30;
$dangercolor-light: #EA6568;
$dangercolor-lightest: #FBD3CB;
$infocolor-darkest: #231A6F;
$infocolor-dark: #362A8A;
$infocolor-medium: #6554C0;
$infocolor-light: #AB9CEC;
$infocolor-lightest: #E5DFFB;
$successcolor-darkest: #218123;
$successcolor-dark: #34A13F;
$successcolor-medium: #61C662;
$successcolor-light: #B9F5A7;
$successcolor-lightest: #EDFDE2;
$warningcolor-darkest: #932200;
$warningcolor-dark: #B73500;
$warningcolor-medium: #FF6801;
$warningcolor-light: #FFB666;
$warningcolor-lightest: #FFECCC;
$typecolor-dark-100: #FFFFFF;
$typecolor-dark-200: #F7F7F7;
$typecolor-dark-300: #F5F5F5;
$typecolor-dark-400: #D8E9F1;
$typecolor-dark-500: #C6D9E3;
$typecolor-dark-600: #AEC3D2;
$typecolor-light-100: #AEC3D2;
$typecolor-light-200: #8794A5;
$typecolor-light-300: #697788;
$typecolor-light-400: #4D5C6C;
$typecolor-light-500: #35424F;
$typecolor-light-600: #1F2933;
$font-family-base: Poppins;
$font-size-xxxs: 12px;
$font-size-xxs: 14px;
$font-size-xs: 16px;
$font-size-sm: 20px;
$font-size-md: 24px;
$font-size-lg: 32px;
$font-size-xl: 40px;
$font-size-xxl: 48px;
$font-size-xxxl: 64px;
$font-size-display: 80px;
$font-size-giant: 96px;
$font-weight-bolder: 800;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$line-height-slim: 100%;
$line-height-medium: 120%;
$line-height-strong: 150%;