@import "../../bootstrap/Tema/variables";

.QrCode-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.QrCode-Grid-Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.QrCodeReader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.Container-Botao-Nova-Retornar {
  width: 85%;
}

.Botao-Finalizar-Code.MuiButton-root {
  color: #02226A;
  background: #fff;
  width: 100%;
  height: 50px;
  border: 2px solid #02226A;
}
.Botao-Finalizar-Code.MuiButton-root:hover {
  color: #fff;
  background: #0d2c74;
  cursor: pointer;
}

.QrCodeButtons {
  display: flex;
  flex-direction: row;
  width: 70vw;
  justify-content: space-between;
}

.QrCodeButtons > .Botao-Finalizar-Code {
  margin-right: 5vh;
}

.Opcoes-Descarte-Finalizar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Botao-Cancelar {
  cursor: pointer;
  z-index: 3000;
}

.Progresso-Circular {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}