@import "../../../bootstrap/Tema/variables";

.Container {
  width: 300px;
  background: #043D94;
  padding: 32px 16px 24px;
  border-radius: 0px 32px 32px 0px;
}

.Box {
  background: #043D94;
}

.Icone {
  width: 40px;
  margin-bottom: 22px;
}

.NomeLogado.MuiTypography-body1 {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  line-height: 120%;
  text-transform: uppercase;
}

.NomeLoja.MuiTypography-body1{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #91B7F0
}
