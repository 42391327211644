.Input-Multiple-Select {
  margin-top: 20px;
}

.Input-Multiple-Select .css-yk16xz-control {
  background-color: #EEEEEE;
  border: none;
  padding: 6px;
}

.Input-Multiple-Select .css-1pahdxg-control:hover {
  background-color: #EEEEEE;
  border: none;
}
.Input-Multiple-Select .css-1pahdxg-control {
  background-color: #EEEEEE;
  border: none;
  box-shadow: 0 0 0 1px #02226A;
}

.Input-Multiple-Select .css-1rhbuit-multiValue {
  display: flex;
  align-items: center;
  background-color: #043D94;
  color: #fff;
  border-radius: 20px;
  padding: 1px;
}
.Input-Multiple-Select .css-xb97g8:hover {
  background-color: #043D94;
  border-radius: 30px;
  color: #fff;
}

.Input-Multiple-Select .css-12jo7m5 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;
}

.Icone-Busca--Multple-Select.MuiSvgIcon-root {
  color: #043D94
}
.Input-Multiple-Select .react-select__placeholder {
  font-size: 14px;
  color: #8794A5;
  letter-spacing: 0.4px;
}

.Menu-Multiple-Select.react-select__menu {
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px #B4B4B4;
  border: 1px solid #FFFFFF;
}

.Menu-Multiple-Select .react-select__menu-list::-webkit-scrollbar {
  display: none;
}

.Menu-Multiple-Select .react-select__option {
  border-bottom: 1px solid #AEC3D2;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #043D94;
}

.Menu-Multiple-Select .react-select__menu-notice {
  border-top: 1px solid #AEC3D2;
  border-bottom: 1px solid #AEC3D2;
}

.Clear-Icon-Multiple-Select.MuiButtonBase-root {
  padding: 0px;
  color: #043D94;
}
.Clear-Icon-Multiple-Select.MuiButtonBase-root:disabled {
  color: #375f9b;
}

.Clear-Indicator.react-select__clear-indicator {
  display: none;
}

@media (max-height: 640px) {
  .Menu-Multiple-Select .react-select__menu-list {
    height: 200px;
  }
}

@media (max-height: 568px) {
  .Menu-Multiple-Select .react-select__menu-list {
    height: 140px;
  }
}
