@import "../../bootstrap/Tema/variables";

.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  height: 56px;
  width: 100%;
  background-color: #F5F5F5;
  border-bottom: 1px solid #C6D9E3;
  box-shadow: none;
}

.Menus-Header.MuiBox-root{
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.Menu-Lateral {
  display: flex;
  align-items: center;
}

.Botao-Voltar.MuiButtonBase-root {
  padding: 0px;
}

.Botao-Voltar .MuiSvgIcon-root {
  height: 26px;
  width: 26px;
  color: #043D94;
}

.Botoes-Cancela-Continua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.Botao-Continuar.MuiButtonBase-root {
  width: 198px;
  height: 50px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #02226A;
  color: #FFFFFF;
  text-transform: none;
}
.Botao-Cancelar.MuiButtonBase-root {
  width: 198px;
  height: 50px;
  margin-top: 16px;
  border-radius: 8px;
  // background: #EA6568;
  border: 1px solid #02226A;
  color: #02226A;

  text-transform: none;
}
.Botao-Continuar.MuiButtonBase-root:hover {
  background-color: #02226A;
}
