@import "../../bootstrap/Tema/variables";

.Chip-Flutuante.MuiFab-secondary {
  background: #FFFFFF;
  color: #8794A5;
  border: 1px solid #8794A5;
}
.Chip-Flutuante.MuiFab-secondary:hover {
  background: #FFFFFF;
  color: #8794A5;
  border: 1px solid #8794A5;
}
.Chip-Flutuante.MuiFab-primary {
  background: #043D94;
  color: #FFFFFF;
}
.Chip-Flutuante.MuiFab-primary:hover {
  background: #043D94;
  color: #FFFFFF;
}
.Chip-Flutuante.MuiFab-root {
  box-shadow: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-transform: none;
  letter-spacing: 0.4px;
}
