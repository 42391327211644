@import "../../../../bootstrap/Tema/variables";

.Botao-Adicionar-Equipamento.MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 20px 0px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #02226A;
  line-height: 120%;
  text-transform: none;
}

.Container-Dialog-Adicionar .MuiPaper-root {
  width: 100%;
  height: 100%;
}
.Container-Dialog-Adicionar .MuiDialog-paperScrollPaper {
  max-height: none;
}
.Container-Dialog-Adicionar .MuiDialog-paper {
  background-color: #F5F5F5;
  border-radius: 22px 22px 0px 0px;
  margin: 0;
}

.Dialog-Actions-Adicionar.MuiDialogActions-root{
  justify-content: center;
  align-items: center;
  height: 0px;
}

.Botao-Arrastar-Adicionar.MuiButtonBase-root {
  background-color: $neutralcolor-light-600;
  height: 4px;
  margin-top: 10px;
}
.Botao-Arrastar-Adicionar.MuiButton-text {
  padding: 4px 6px;
}

.Dialog-Content-Adicionar.MuiDialogContent-root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;
}

.Dialog-Grid-Container-Adicionar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Dialog-Grid-Container-Adicionar::-webkit-scrollbar {
  display: none;
}

.Dialog-Grid-Titulo-Botao-Adicionar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.Dialog-Titulo-Novo.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 136%;
  color: $brandcolor-primary-darkest;
}

.Dialog-Botao-Fechar-Adicionar.MuiButtonBase-root {
  color: $brandcolor-primary-light;
  padding: 0px;
}
.Dialog-Botao-Fechar-Adicionar .MuiSvgIcon-root {
  height: 18px;
}

.Dialog-Grid-Lista-Equip.MuiGrid-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
}

.Campo-Busca-Equipamento .MuiInputBase-root {
  height: 40px;
  border-radius: 4px;
  margin-top: 48px;
}
.Campo-Busca-Equipamento .MuiInputBase-input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #043D94;
}
.Campo-Busca-Equipamento .MuiInputAdornment-root {
  color: #043D94;
}
.Campo-Busca-Equipamento .MuiFilledInput-input {
  padding: 0px;
  margin-left: 12px;
}
.Campo-Busca-Equipamento .MuiFilledInput-underline::before {
  content: none;
}
.Campo-Busca-Equipamento .MuiFilledInput-underline::after {
  content: none;
}

.Resultados.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #043D94;
  margin: 16px 0px 8px 0px
}

.Lista-Container {
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Lista-Container::-webkit-scrollbar {
  display: none;
}

.ListItem-Novos-Equip.MuiListItem-root {
  display: flex;
  flex-direction: column;
  padding: 4px;
  align-items: baseline;
  border-top: 1px solid #AEC3D2;
}

.Nome-Equip.MuiTypography-body1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #043D94;
  margin-top: 3px;
}
.Cod-Equip.MuiTypography-body1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  display: flex;
  align-items: center;
  color: #4D5C6C;
  margin: 8px 0px 4px 0px;
}

.Modal-Inserir-Novo-Equip .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 255px;
  width: 360px;
  background: #F5F5F5;
  margin: auto 0px 0px 0px;
  border-radius: 16px 16px 0px 0px;
}
.arrasta-fecha-novo-equip.MuiButton-root {
  background: #C6D9E3;
  width: 56px;
  height: 6px;
  padding: 0px;
  margin: 11px 0px 16px 0px;
}
.Nome-Novo-Equip.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
  margin: 0px 24px 16px 24px;
}
.Codigo-Novo-Equip.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #4D5C6C;
  margin: 0px 0px 24px 0px;
}
.div-botoes-novo-equip.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 108px;
  padding: 0px 24px 0px 24px;
}
.Adicionar-Novo-Equip.MuiButtonBase-root {
  background: #02226A;
  border-radius: 8px;
  padding: 16px 24px;
  color: #FFFFFF;
  text-transform: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  width: 100%;
}
.Adicionar-Novo-Equip.MuiButtonBase-root:hover {
  color: #FFFFFF;
  background: #02226A;
}
.cancelar-novo-equip.MuiButtonBase-root {
  background: transparent;
  border: 1px solid #02226A;
  border-radius: 8px;
  padding: 16px 24px;
  color: #02226A;
  text-transform: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  width: 100%;
}
.cancelar-novo-equip.MuiButtonBase-root:hover {
  color: #02226A;
  background: transparent;
}
.Snack-Novo-Equip {
  align-items: center;
}
