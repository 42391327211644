@import "../../../bootstrap/Tema/variables";

.Container-Botao-Nova-Retornar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 24px;
  margin: auto 0px 14px 0px;
}

.Botao-Nova-Retornar.MuiButton-root {
  color: #fff;
  background: #02226A;
  width: 100%;
  height: 50px;
  border-radius: 8px;
}
.Botao-Nova-Retornar.MuiButton-root:hover {
  color: #fff;
  background: #0d2c74;
}

.Container-Dialog .MuiPaper-root {
  width: 100%;
  height: 100%;
}
.Container-Dialog .MuiDialog-paperScrollPaper {
  max-height: none;
}
.Container-Dialog .MuiDialog-paper {
  background-color: #F5F5F5;
  border-radius: 22px 22px 0px 0px;
  margin: 0;
}

.Dialog-Actions.MuiDialogActions-root{
  justify-content: center;
  align-items: center;
  height: 0px;
}

.Botao-Arrastar.MuiButtonBase-root {
  background-color: $neutralcolor-light-600;
  height: 4px;
  margin-top: 10px;
}
.Botao-Arrastar.MuiButton-text {
  padding: 4px 6px;
}

.Dialog-Content.MuiDialogContent-root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: auto;
}
.Dialog-Content.MuiDialogContent-root::-webkit-scrollbar {
  display: none;
}

.Dialog-Grid-Container-Nova {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  // overflow: auto;
}

.Dialog-Grid-Titulo-Botao {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.Dialog-Form-Titulo.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 136%;
  color: $brandcolor-primary-darkest;
}

.Dialog-Form-Botao-Fechar.MuiButtonBase-root {
  color: $brandcolor-primary-light;
  padding: 0px;
}
.Dialog-Form-Botao-Fechar .MuiSvgIcon-root {
  height: 18px;
}

.Dialog-Grid-Form-Nova {
  display: flex;
  width: 100%;
  height: 90%;
}
