@import "../../../../bootstrap/Tema/variables";

.Dialog-Form-Nova {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.Dialog-Corpo-Form {
  width: 100%;
  height: 100%;
}

.Loja-Form-Nova.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  margin-top: 32px;
}

.Input-Loja-Nova .MuiInput-underline:before {
  border-bottom: 1px solid $neutralcolor-dark-100;
}
.Input-Loja-Nova .MuiInput-underline:after {
  border-bottom: 1px solid $neutralcolor-dark-100;
}
.Input-Loja-Nova .MuiSvgIcon-root {
  color: $brandcolor-primary-light;
}

.Tipo-Contagem-Form-Nova.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  margin-top: 24px;
}

.Finalizar-Nova.MuiButton-root {
  color: #FFF;
  background-color: $brandcolor-primary-medium;
  height: 50px;
  width: 312px;
}
.Finalizar-Nova.MuiButton-root:hover {
  color: #FFF;
  background-color: $brandcolor-primary-light;
}
.Finalizar-Nova.MuiButton-root:disabled {
  color: #697991;
  background-color: #183868;
}
.Finalizar-Nova.MuiButton-root:hover.Mui-disabled {
  color: #697991;
  background-color: #183868;
}

.Chips-Nova-Contagem {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  width: 170px;
}

.Grupo-Equip-Nova.MuiTypography-body1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  margin-top: 24px;
}

.Snackbar-Nova.MuiSnackbar-root {
  margin-bottom: 70px;
}
.Snackbar-Nova .MuiSnackbarContent-message {
  font-size: 14px;
  line-height: 100%;
  width: 80%;
}

.Progresso-Circular-Nova-Contagem.MuiCircularProgress-root {
  color: #95C6F4;
}

.Progresso-Circular-Nova-Contagem {
  margin-top: 20px;
}
