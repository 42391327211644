@import "../../../bootstrap/Tema/variables";

.Inputs {
  display: flex;
  flex-direction: column;
}

.Inputs .Input-Error {
  display: flex;
  justify-content: flex-start;
  color: $dangercolor-medium;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
  margin: 0px 0px 0px 2px;
}

.Divisao-Erros {
  display: flex;
  align-items: center;
  padding: 0.5px;
}

.Icone-Input-Error.MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  color: $dangercolor-medium;
}

.Alterar-Cor label {
  color: $dangercolor-medium;
}
