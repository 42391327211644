.btn-enviar-email.MuiButtonBase-root {
  width: 312px;
  height: 50px;
  padding: 16px 24px;
  background: #02226A;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #FFFFFF;
  text-transform: none;
}

.btn-enviar-email.MuiButtonBase-root:hover {
  background: #02226A;
}

.dialog-enviar-email .MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 306px;
  width: 360px;
  background: #F5F5F5;
  margin: auto 0px 0px 0px;
  border-radius: 16px 16px 0px 0px;
  padding: 0px 24px 24px 24px;
}
.botao-arrastar-fechar-email.MuiButton-root {
  background: #C6D9E3;
  width: 56px;
  height: 6px;
  padding: 0px;
  margin: 11px 0px 24px 0px;
}
.Titulo-dialog-email.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
  margin: 0px 0px 16px 0px;
}
.Box-botoes-email.MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 108px;
  margin: auto 0px 0px 0px;
}
.btn-confirmar.MuiButtonBase-root {
  background: #02226A;
  border-radius: 8px;
  text-transform: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 16px 24px;
  color: #FFFFFF;
  width: 100%;
  height: 50px;
}
.btn-confirmar.MuiButtonBase-root:hover {
  background: #042f94;
  color: #FFFFFF;
}
.btn-cancelar.MuiButtonBase-root {
  background: #FFFFFF;
  border: 1px solid #02226A;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #02226A;
  text-transform: none;
  padding: 16px 24px;
  width: 100%;
  height: 50px;
}
.circular-progress-dialog.MuiCircularProgress-root {
  color: #95C6F4;
  margin: auto;
}
.icone-fechar-email.MuiIconButton-root {
  padding: 0px;
  margin: -16px 0px 0px auto;
}
.icone-fechar-email .MuiSvgIcon-root {
  color: #043D94;
  height: 20px;
  width: 20px;
}
.icone-conclusao {
  margin: 47px 0px 24px 0px;
}
.texto-email-enviado.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #041D5D;
}
.input-email .MuiFormHelperText-contained {
  margin-left: 0px;
}
