@import "../../bootstrap/Tema/variables";

.Botao-Filtrar.MuiButtonBase-root {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-left: 10px;
  padding: 0px;
  border-radius: 0px;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.4px;
  background-color: transparent;
}
.Botao-Filtrar.MuiButtonBase-root:hover {
  background-color: transparent;
}
.Botao-Filtrar .MuiButton-startIcon {
  margin: 0px;
}
.Botao-Filtrar .MuiButton-iconSizeMedium > *:first-child {
  font-size: 16px;
}

.Drawer-Filtro .MuiDrawer-paperAnchorBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 22px 22px 0px 0px;
  background: #F5F5F5
}
.Drawer-Filtro .MuiPaper-elevation0 {
  -webkit-box-shadow: 0px 0px 200px 300px rgba(0,0,0,0.40);
  box-shadow: 0px 0px 200px 300px rgba(0,0,0,0.40);
}

.Botao-Arrastar-Filtro.MuiButtonBase-root {
  background-color: $neutralcolor-light-600;
  height: 4px;
}
.Botao-Arrastar-Filtro.MuiButton-text {
  padding: 4px 6px;
}

.Titulo-Modal-Filtro.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 136%;
  color: $brandcolor-primary-darkest;
}

.Titulo-Botao-Fechar-Filtro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 18px;
}
.Botao-Fechar-Filtro.MuiButtonBase-root {
  color: $brandcolor-primary-light;
  padding: 0px;
}
.Botao-Fechar-Filtro .MuiSvgIcon-root {
  height: 18px;
}

.Form-Filtro form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.Form-Filtro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 100%;
  overflow: auto;
}

.Titulo-Campos.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
}
.Campo-Loja-Filtro {
  margin-top: 34px;
}

.Campo-Datas-Filtro, .Campo-Tipo-Filtro, .Campo-Grupo-Filtro {
  margin-top: 24px;
}
.DatePicker-Filtro .MuiSvgIcon-root {
  color: #043D94;
}
.DatePicker-Filtro .MuiInput-underline:before,
.DatePicker-Filtro .MuiInput-underline:after {
  border-bottom: 1px solid #AEC3D2;
}
.DatePicker-Filtro .MuiInputBase-root {
  color: #AEC3D2;
}

.Chips-Filtros {
  display: flex;
  margin-top: 16px;
}

.Chips-Filtros div:last-child {
  margin-left: 8px;
}

.Botoes-Filtro {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 24px;
  width: 100%;
}
.Botao-Remover-Filtro.MuiButtonBase-root {
  width: 152px;
  height: 50px;
  background: #02226A;
  color: #FFFFFF;
  text-transform: none;
  border-radius: 8px;
}
.Botao-Remover-Filtro.MuiButtonBase-root:hover {
  background: #02226A;
}

.Botao-Aplicar-Filtro.MuiButtonBase-root {
  width: 152px;
  height: 50px;
  background: #02226A;
  color: #FFFFFF;
  text-transform: none;
  border-radius: 8px;
}
.Botao-Aplicar-Filtro.MuiButtonBase-root:hover {
  background: #02226A;
}

.Progresso-Circular-Filtro.MuiCircularProgress-root {
  color: #95C6F4;
}
.Progresso-Circular-Filtro {
  margin: auto;
}

@media screen and (min-width: 650px) {
  .Drawer-Filtro .MuiDrawer-paperAnchorBottom {
    width: 50%;
    margin: auto;
  }
}
