.Drawer-Menu .MuiDrawer-paper{
  border-radius: 0px 36px 0px 0px;
  width: 20%;
}

@media (max-width: 800px) {
  .Drawer-Menu .MuiDrawer-paper {
    width: 30%
  }
}

@media (max-width: 450px) {
  .Drawer-Menu .MuiDrawer-paper {
    width: 55%
  }
}

@media (max-width: 321px) {
  .Drawer-Menu .MuiDrawer-paper {
    width: 65%
  }
}
