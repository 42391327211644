@import "../../bootstrap/Tema/variables";

.Contagem-Andamento.MuiGrid-root {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
}

.Contagem-Andamento-Grid-Item {
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
