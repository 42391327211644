@import "../../../bootstrap/Tema/variables";

.Scroll-Infinita-Outras::-webkit-scrollbar {
  display: none;
}
.Scroll-Infinita-Outras {
  overflow: auto;
  height: 450px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Cards-Outras-Contagens.MuiPaper-root {
  width: 100%;
}

.List-Card-Outras-Contagens .MuiPaper-root {
  background-color: transparent;
}
.List-Card-Outras-Contagens .MuiPaper-elevation1 {
  box-shadow: none;
}

.Card-Content-Outras-Contagens.MuiCardContent-root {
  padding: 0px;
}
.Card-Content-Outras-Contagens.MuiCardContent-root:last-child {
  padding: 0px;
}

.Sem-Contagns-Ativas.MuiTypography-root {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
}

.Campo-Em-Andamento.MuiTypography-root {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #043D94;
  margin-bottom: 5px;
}

.linha-usuario-seta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Data-Outras-Contagens.MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #697788;
  text-align: right;
}

.Nome-Codigo-Loja-Outras.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #001147;
}

.Nome-Usuario-Outras.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 136%;
  color: #043D94;
  margin: 0px 0px 5px 0px;
}

.Tipo-Contagem-Outras.MuiTypography-root {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: left;
  color: #001147;
}

@media (max-height: 700px) {
  .Nome-Usuario-Outras.MuiTypography-root {
    font-size: 12px;
  }

  .Tipo-Contagem-Outras.MuiTypography-root {
    font-size: 12px;
  }
}

@media (min-height: 480px) {
  .Scroll-Infinita-Outras {
    height: 240px;
  }
}

@media (min-height: 568px) {
  .Scroll-Infinita-Outras {
    height: 320px;
  }
}

@media (min-height: 640px) {
  .Scroll-Infinita-Outras {
    height: 380px;
  }
}

@media (min-height: 720px) {
  .Scroll-Infinita-Outras {
    height: 455px;
  }
}

@media (min-height: 736px) {
  .Scroll-Infinita-Outras {
    height: 470px;
  }
}

@media (min-height: 812px) {
  .Scroll-Infinita-Outras {
    height: 535px;
  }
}

@media (min-height: 979px) {
  .Scroll-Infinita-Outras {
    height: 680px;
  }
}

@media (min-height: 1024px) {
  .Scroll-Infinita-Outras {
    height: 730px;
  }
}

