@import "../../../bootstrap/Tema/variables";

.Botao-Expandir {
  display: flex;
  align-items: center;
}

.Contagem-Ativas {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #001147;
}

.Contagem-Encerradas {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #001147;
}

.Botao-Expandir-Encerradas {
  display: flex;
  align-items: center;
}

.Cards{
  width: 100%;
}
.Cards .MuiCardContent-root {
  padding: 0;
}
.Cards .MuiCardContent-root:last-child {
  padding: 0;
}

.Card-Content div:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Card-Content button {
  height: 8px;
  width: 5px;
}

.list-container-ativas.MuiList-root {
  padding-bottom: 0px;
  padding-top: 0px;
  width: 100%;
}
.list-container-encerradas.MuiList-root {
  padding-bottom: 0px;
  width: 100%;
}
