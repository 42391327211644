@import "../../../bootstrap/Tema/variables";

.Container-DatePicker {
  display: flex;
  flex-wrap: wrap;
}

.Container-DatePicker.TextField {
  margin-left: 8px;
  margin-right: 8px;
  width: 200;
}

.Span-DatePicker {
  color: $dangercolor-medium;
  font-weight: 300;
  font-size: 14px;
  line-height: 136%;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    opacity:0;
    -webkit-appearance: none;
}
